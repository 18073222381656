<script lang="ts">
  import type { TextField } from "@customTypes/statamicFields";
  import Button from "@elements/Button.svelte";
  import { Turnstile } from "svelte-turnstile";
  import { fly } from "svelte/transition";
  // Props
  export let title: TextField = "";
  export let subtitle: TextField = "";
  export let form_action: TextField = "";
  export let disclaimer: TextField = "";
  export let success_title: TextField = "You're almost there!";
  export let success_subtitle: TextField =
    "Please confirm your subscription by clicking the link we just emailed you.";

  // State
  let error: string | null = null;
  let isSuccess: boolean = false;
  let isLoading: boolean = false;
  let containerHeight: number = 0;

  // Methods
  async function handleError(error: string) {
    switch (error) {
      case "cloudflare-turnstile":
        error = "Please complete the captcha";
        break;
      default:
        error =
          "Something went wrong adding you to the list. Please try again later.";
    }
  }

  async function handleSuccess() {
    isSuccess = true;
  }

  async function handleSubmit(e: Event) {
    isLoading = true;
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    try {
      const response = await fetch(form_action, {
        method: "POST",
        body: formData,
        redirect: "manual",
        mode: "cors", // Ensure it's a CORS request
      });

      if (response.ok) {
        const data = await response.json();
        if (!data.error) {
          handleSuccess();
        } else {
          handleError(data.error);
        }
      } else {
        handleError("unknown");
      }
    } catch (error) {
      handleError("unknown");
    }
    isLoading = false;
  }

  function setContainerHeight(node: HTMLElement) {
    containerHeight = node.offsetHeight;
  }
</script>

<section id="subscribe">
  {#if isSuccess}
    <div
      style="min-height: {containerHeight}px;"
      class="container text-white bg-n900 md:px-20 flex flex-col items-center justify-center py-10"
    >
      <h2
        transition:fly={{ y: 50, duration: 1500 }}
        class="text-center text-s100 text-hero2 font-extralight"
      >
        {success_title}
      </h2>
      <p
        transition:fly={{ y: 50, duration: 1500, delay: 100 }}
        class="text-center my-3 text-body-large font-extralight"
      >
        {success_subtitle}
      </p>
    </div>
  {:else if error}
    <div
      style="min-height: {containerHeight}px;"
      class="container text-white bg-n900 md:px-20 flex flex-col items-center justify-center py-10"
    >
      <h2 class="text-center text-s100 text-hero2 font-extralight">
        Something went wrong adding you to the list.
      </h2>
      <p class="text-center my-3 text-body-large font-extralight">
        Please try again later.
      </p>
    </div>
  {:else}
    <form
      use:setContainerHeight
      on:submit|preventDefault={handleSubmit}
      id="email-subscription-form"
      action={form_action}
      method="post"
      class={`container text-white bg-n900 md:px-20 flex flex-col items-center py-10 transition-opacity duration-300 ${isLoading ? "opacity-50" : ""}`}
    >
      <h2 class="text-center text-s100 text-hero2 font-extralight">{title}</h2>
      <p class="text-center my-3 text-body-large font-extralight">
        {subtitle}
      </p>
      {#if disclaimer}
        <p class="text-white text-center text-body-small mt-4">{disclaimer}</p>
      {/if}
      <div class="w-full max-w-screen-md mt-4 text-black">
        <div class="flex flex-col sm:flex-row gap-4">
          <input
            class="text-body-base m-0 grow inline-block rounded-md p-4"
            name="first_name"
            type="text"
            required
            disabled={isLoading}
            placeholder="First Name"
          />
          <input
            class="text-body-base m-0 grow inline-block rounded-md p-4"
            name="last_name"
            type="text"
            required
            disabled={isLoading}
            placeholder="Last Name"
          />
        </div>
        <div class="flex flex-col sm:flex-row gap-4 mt-4">
          <input
            class="text-body-base m-0 grow inline-block rounded-md p-4"
            name="email"
            type="email"
            required
            placeholder="Email Address"
            disabled={isLoading}
          />
          <Button
            {isLoading}
            isDisabled={isLoading}
            type="submit"
            text="Subscribe"
            variant="secondary"
          />
        </div>
        <div
          class="flex flex-col sm:flex-row justify-between items-center gap-4 mt-4"
        >
          {#if import.meta.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
            <Turnstile
              theme="dark"
              siteKey={import.meta.env.PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY}
            />
          {/if}
        </div>
      </div>
    </form>
  {/if}
</section>
